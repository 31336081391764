import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    home: {
                        mainTitle: 'Your next car is here!',
                        searchNearYou: 'Search Near You',
                        search: 'Search',
                        topSearch: 'Top Searches',
                        dailyDeals: 'Daily deals',
                        dailyDealsExplain: 'We have daily specials, check it out!',
                        reload: 'Reload',
                        aboutUs: 'About Us',
                        aboutDetail: 'We are trying our best to deliver the car fits your needs at the most reasonable price.',
                        selectBrand: 'Select Brand',
                        selectSeries: 'Select Series',
                        login: "Login",
                        signUp: "Sign Up"
                    },
                    search: {
                        loadingForCar: 'Please wait for the amazing cars!',
                        noCarFound: 'No result found! Please try again or try to search something else.',
                        noCondition: 'Please select a brand to start searching!',
                        minYear: 'Minimum Year',
                        maxYear: 'Maximum Year',
                        minOdm: 'Minimum Odometer',
                        maxOdm: 'Maximum Odometer',
                        zipCode: 'ZIP Code',
                        searchResults: 'SEARCH RESULTS',
                        vehicleType: 'Vehicle Type',
                        miles: 'miles',
                        waitTimeExplain: 'We are trying to provide you the best results, some cars might take a longer time to search.',
                        carSold: 'This car might be sold, please try another one.',
                        interested: 'Interested?',
                        locate: 'Locate In: ',
                        exteriorColor: 'Exterior Color: ',
                        interiorColor: 'Interior Color: ',
                        year: "Year",
                        submitted: "Your message is received, we will get back to you as soon as we can!"
                    },
                    details: {
                        odometer: 'Odometer',
                        miles: 'miles',
                        driveChain: 'Drive Chain',
                        engine: 'Engine',
                        interiorType: 'Interior Type',
                        overview: 'Overview',
                        MPInfo: 'Manufacturer Package Info',
                        SPInfo: 'Safety Packages Info',
                        IPInfo: 'Interior Packages Info',
                        EPInfo: 'Exterior Packages Info',
                        IFInfo: 'Infotainment Packages Info',
                        OPInfo: 'Other Packages Info',
                        comments: 'Comments',
                        price: 'Price'
                    },
                    interestForm: {
                        error: 'Error sending your interests! Please retry.'
                    },
                    myProfile: {
                        savedCars: "Saved Cars",
                        messages: "Messages",
                        noSavedCars: "You don't have saved cars, start browsing now!",
                        registrationFailed: "Registration failed. Please try again!"
                    }
                }
            },
            cn: {
                translation: {
                    home: {
                        mainTitle: '你的下一辆车就在这里等着你！',
                        searchNearYou: '现在开始搜索吧',
                        search: '搜索',
                        topSearch: '热门车型',
                        dailyDeals: '每日特惠',
                        dailyDealsExplain: '每天我们都有不同的特惠，快来看看吧！',
                        reload: '换一换',
                        aboutUs: '关于我们',
                        aboutDetail: 'We are trying our best to deliver the car fits your needs at the most reasonable price.',
                        selectBrand: '选择品牌',
                        selectSeries: '选择车系',
                        login: "Login",
                        signUp: "Sign Up"
                    },
                    search: {
                        loadingForCar: '请稍等！',
                        noCarFound: '没有找到符合条件的车型，请重试或者试试搜索别的车型。',
                        noCondition: '请首先选择一个品牌',
                        minYear: '最小年份',
                        maxYear: '最大年份',
                        minOdm: '最小公里数',
                        maxOdm: '最大公里数',
                        zipCode: '邮编',
                        searchResults: '搜索结果',
                        vehicleType: '车辆类型',
                        miles: '英里',
                        waitTimeExplain: '有些车型可能需要较长的搜索时间，请耐心等待。',
                        carSold: '检索不到这辆车，请尝试其他车型。',
                        interested: '感兴趣?',
                        locate: '所在州: ',
                        exteriorColor: '车外颜色: ',
                        interiorColor: '内饰颜色: ',
                        year: "年份",
                        submitted: "收到您的请求，我们会尽快回复您！"
                    },
                    details: {
                        odometer: '里程数',
                        miles: '英里',
                        driveChain: '驱动方式',
                        engine: '发动机',
                        interiorType: '内饰',
                        overview: '总览',
                        MPInfo: '原厂配置',
                        SPInfo: '安全配置',
                        IPInfo: '内饰配置',
                        EPInfo: '外观配置',
                        IFInfo: '娱乐系统配置',
                        OPInfo: '其他配置',
                        comments: '卖家备注',
                        price: '价格'
                    },
                    interestForm: {
                        error: '发生了错误，请重试！'
                    },
                    myProfile: {
                        savedCars: "保存的车辆",
                        messages: "消息",
                        noSavedCars: "您还没有喜欢的车，现在快去看看吧！",
                        registrationFailed: "注册失败，请重试!"
                    }
                }
            }
        }
    });

export default i18n;