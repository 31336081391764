import React, {useState} from 'react'
import {useTranslation, Trans} from 'react-i18next';
import './Footer.css'

import Modal from "./Modal";

function Footer() {
    const {i18n} = useTranslation();
    const {t} = useTranslation();

    const [openModal, setOpenModal] = useState(false);

    function navigateHome() {
        setOpenModal(true)
    }

    return (
        <div>
            <div id="footer">
                <p className={"qrCodeDesc"}>Buy me a coffee: </p>
                <a target={"_blank"} href='https://www.buymeacoffee.com/urbandict'><img className={"qrCodeImg"} src={"bmc_qr.png"} alt={"https://www.buymeacoffee.com/urbandict"}/></a>
            </div>
        </div>
    )
}

export default Footer