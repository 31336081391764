import "./LoginPage.css"
import {GoogleLogin} from "@react-oauth/google";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

const LoginPage = () => {

    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('/user/login', {
                username,
                password
            });
            if (response.status === 200) {
                // Login successful, redirect to /myProfile
                navigate("/myProfile", response.data);
            }
        } catch (error) {
            console.error('Login failed:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className="login-page">
            <div className="login-outer-form">
                <p className={"titleP"}>Login to see&save your favorite cars, contact us about your interests and
                    more!</p>
                <form className="login-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button>Sign In</button>
                    <div className={"googleLogin"}>
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                console.log(credentialResponse);
                                axios
                                    .post('/api/postGoogleSignIn', {
                                        params: {
                                            credential: credentialResponse.credential
                                        }
                                    })
                                    .then(response =>
                                        navigate("/myProfile", {state: response.data})
                                    ).then(response => {

                                })
                                    .catch(error => {

                                    });
                            }}

                            onError={() => {
                                console.log('Login Failed');
                            }}

                        />
                    </div>
                    <p className="message">Don't have account? <a href="/register">Register one!</a></p>
                </form>
            </div>
        </div>

    )
};

export default LoginPage;