import React from "react";
import "./LoadingSpinner.css";
import {useTranslation} from "react-i18next";

export default function LoadingSpinner() {
    const {t} = useTranslation();

    return (
        <div id="overlay">
            <div id="loadingText">请稍等</div>
            <div className="spinner-container">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}