import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Home.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import { Helmet } from 'react-helmet';
import SearchBar from './SearchBar';
import ModalWithInput from "./ModalWithInput";
import Modal from "./Modal";

const Home = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [openModalWithInput, setOpenModalWithInput] = useState(false);
    const [openCommonModal, setOpenCommonModal] = useState(false);

    function openInputModal() {
        setOpenModalWithInput(true)
    }

    function closeInputModalAndOpenAlertModal() {
        setOpenModalWithInput(false)
    }

    function openModal() {
        setOpenCommonModal(true)
    }

    function closeOpenCommonModal() {
        setOpenCommonModal(false)
    }


    useMemo(() => {
        axios.get('https://geolocation-db.com/json/')
            .then(response => {
                addVisit(response.data.IPv4)
            }, []);
    })

    function addVisit(ip) {
        localStorage.setItem('userIP', ip);
        let url = '/urbanx/word/addVisit?ipAddress=' + ip
        axios.post(url);
    }

    function handleSearch() {}

    return (
        <>
            <div className={"rootDiv"}>
                <Helmet>
                    <title>英语俚语词典</title>
                </Helmet>
                <Modal
                    open={openCommonModal}
                    onClose={() => closeOpenCommonModal()}/>
                <ModalWithInput
                    open={openModalWithInput}
                    onClose={() => closeInputModalAndOpenAlertModal()}/>
                <div className={"titleDiv"}>
                    <div className={"imgAndTitleDiv"}>
                        <img className={"smallLogo"} src={"logo_small.jpg"}/><h1>英语俚语词典</h1>
                    </div>
                    <img className={"wordImgDiv"} src={"word.jpg"}/>
                </div>
                {isLoading ? <LoadingSpinner /> : ''}
                <div className={'searchBarDiv'}>
                    <SearchBar onSearch={handleSearch} />
                </div>
                <div className={"buttonDiv"}>
                    <button onClick={() => openModal()}>随便看看</button>
                    <button onClick={openInputModal}>没找到？</button>
                </div>
            </div>
        </>
    );
};

export default Home;
