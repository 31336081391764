import React, {useEffect, useState} from 'react'
import './SearchBar.css'
import {ReactSearchAutocomplete} from 'react-search-autocomplete'

function SearchBar() {
    const [items, setItems] = useState([])
    const [selectedEnglishWord, setSelectedEnglishWord] = useState("")
    const [selectedChineseWord, setSelectedChineseWord] = useState("")
    const [selectedExample, setSelectedExample] = useState("")
    const [selectedTag, setSelectedTag] = useState("")
    const handleOnSearch = async (string, results) => {
        const ipAddress = localStorage.getItem('userIP');
        try {
            const response = await fetch(`/urbanx/word/getWordSuggestion?meaning=${string}&ipAddr=${ipAddress}`);
            const data = await response.json();

            if (data.word) {
                const items = data.word.map((name, index) => ({
                    id: index,
                    name: name
                }));

                setItems(items);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleOnSelect = async (item) => {
        const ipAddress = localStorage.getItem('userIP');
        try {
            const response = await fetch(`/urbanx/word/getWord?meaning=${item.name}&ipAddr=${ipAddress}`);
            const data = await response.json();

            if (data.word) {
                setSelectedEnglishWord(data.word.eng);
                setSelectedChineseWord(data.word.chs);
                setSelectedExample(data.word.example);
                setSelectedTag(data.word.tag);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatResult = (item) => {
        return (
            <>
                <span style={{display: 'block', textAlign: 'left'}}>{item.name}</span>
            </>
        )
    }

    useEffect(() => {
        // Fetch data from /getTodayWord API when the component is loaded
        const fetchTodayWord = async () => {
            try {
                const response = await fetch('/urbanx/word/getTodayWord');
                const data = await response.json();
                setSelectedEnglishWord(data.eng);
                setSelectedChineseWord(data.chs);
                setSelectedExample(data.example);
                setSelectedTag(data.tag);
            } catch (error) {
                console.error('Error fetching today\'s word:', error);
            }
        };

        fetchTodayWord();
    }, []); // The empty dependency array ensures that this effect runs only once when the component mounts


    return (
        <div className={"searchBar"}>
            <ReactSearchAutocomplete
                items={items}
                onSearch={handleOnSearch}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatResult}
                placeholder={"Search a slang!"}
            />
            <div className={"defDiv"}>
                <p>English: {selectedEnglishWord}</p>
                <p>中文释义: {selectedChineseWord}</p>
                <p>Example: {selectedExample}</p>
            </div>
        </div>
    )
}

export default SearchBar