import {useLocation, useNavigate} from "react-router-dom";
import "./MyProfile.css"
import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "./Modal";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";

const MyProfile = () => {

    const [activeMenu, setActiveMenu] = useState('savedCars');
    const [currentPage, setCurrentPage] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [openAlertModal, setOpenAlertModal] = useState(false);
    const [openModalWithInput, setOpenModalWithInput] = useState(false);
    const [interestedCarVin, setInterestedCarVin] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const itemsPerPage = 10;

    const initialCars = location.state?.cars || [];
    const [cars, setCars] = useState(initialCars);

    const {t} = useTranslation();

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * itemsPerPage;
        const lastPageIndex = firstPageIndex + itemsPerPage;
        return cars.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, cars]);

    function goToDetails(vin) {
        setIsLoading(true);
        axios
            .get('/api/getCarDetails', {
                params: {
                    vin: vin
                }
            })
            .then(response =>
                navigate("/carDetails", {state: response.data})
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setOpenModal(true)
                setIsLoading(false)
            });
    }

    function openModalWithInputFunction(vin) {
        setOpenModalWithInput(true)
        setInterestedCarVin(vin);
    }

    const openCity = (cityName) => {
        setActiveMenu(cityName);
    };

    function closeInputModalAndOpenAlertModal() {
        setOpenModalWithInput(false)
        setOpenAlertModal(true)
        setInterestedCarVin(null)
    }

    return (
        <div className="app-container">
            {isLoading ? <LoadingSpinner/> : ""}
            <div className="w3-sidebar w3-bar-block w3-light-grey w3-card">
                <button className={`w3-bar-item w3-button tablink ${activeMenu === 'savedCars' ? 'w3-red' : ''}`} onClick={() => openCity('savedCars')}>{t('myProfile.savedCars')}</button>
                <button className={`w3-bar-item w3-button tablink ${activeMenu === 'messages' ? 'w3-red' : ''}`} onClick={() => openCity('messages')}>{t('myProfile.messages')}</button>
            </div>

            <div style={{ marginLeft: '130px' }}>
                <div id="London" className={`w3-container city ${activeMenu === 'savedCars' ? 'active' : ''}`}>
                    <h2>{t('myProfile.savedCars')}</h2>
                    {
                        currentTableData.length > 0 ?
                        currentTableData.map((car) => (
                        <div className="divForEachCar">
                            <Modal
                                open={openAlertModal}
                                content={t('search.submitted')}
                                onClose={() => setOpenAlertModal(false)}/>
                            <div className="divLeft">
                                <img className="carImage" onClick={() => goToDetails(car.vin)}
                                     src={car.imgSrc}/>
                            </div>
                            <div className="divRight">
                                <div className={"divInsideRight"}>
                                    <div className={"insideLeft"}>
                                        <h2 onClick={() => goToDetails(car.vin)} className="searchDivTitle"
                                            id="titleOfCar">{car.carName}</h2>
                                        <h4 id="milesOfCar">{car.odometer} {t('search.miles')}</h4>
                                        <p id="drivenChainOfCar">{car.driveChain}</p>
                                        <p id="engineOfCar">{car.engineInfo}</p>
                                        <p id="exteriorColorOfCar">{t('search.exteriorColor')} {car.exteriorColor}</p>
                                        <p id="interiorColorOfCar">{t('search.interiorColor')} {car.interiorColor}</p>
                                        <p id="locationState">{t('search.locate')} {car.locatedState}</p>
                                    </div>
                                    <div className={"insideRight"}>
                                        <h3 id="price">{t('details.price')}: $ {car.price}</h3>
                                        <button onClick={() => openModalWithInputFunction(car.vin)}
                                                className={"interestedButton"}>{t('search.interested')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )) :
                            <div>
                        <p>You don't have saved cars, start browsing now!</p>
                        </div>
                    }


                </div>
                <div id="Paris" className={`w3-container city ${activeMenu === 'messages' ? 'active' : ''}`}>
                    <h2>{t('myProfile.messages')}</h2>
                    <p>Paris is the capital of France.</p>
                    <p>The Paris area is one of the largest population centers in Europe, with more than 12 million inhabitants.</p>
                </div>
            </div>
        </div>
    )
};

export default MyProfile;