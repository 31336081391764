import React, {useEffect, useMemo, useState} from 'react';
import "./Modal.css"
import axios from "axios";

const Modal = ({ open, onClose}) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/urbanx/word/getWordWithSameTag?tag=life&limit=10`);
                const data = await response.json();

                if (data) {
                    setItems(data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures it runs only once on mount


    const fetchData = async () => {
        try {
            const response = await fetch(`/urbanx/word/getWordWithSameTag?tag=life&limit=10`);
            const data = await response.json();

            if (data) {
                setItems(data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    if (!open) return null;

    function addVisit(ip) {
        let url = '/api/addVisit?ipAddress=' + ip;
        axios.post(url);
    }

    return (
        <div onClick={onClose} className='overlay'>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className='modalContainer'
            >
                <button className='closeButton' onClick={onClose}>X</button>
                <button className={"refreshButton"} onClick={fetchData}>&#x21bb;</button>

                <div className='modalRight'>
                    <div className='content'>
                        {items.map(item => (
                            <div key={item.id}>
                                <p>English: {item.eng}</p>
                                <p>中文释义: {item.chs}</p>
                                <p>例句: {item.example}</p>
                            </div>
                        ))}
                    </div>
                    <div className='btnContainer'>
                        <button className='btnPrimary' onClick={onClose}>
                            <span className='bold'>OK</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;