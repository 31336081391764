import React, {useEffect, useState} from 'react';
import "./ModalWithInput.css"
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import { t } from "i18next";

const ModalWithInput = ({ open, onClose}) => {

    const [eng, setEng] = useState("");
    const [chs, setChs] = useState("");
    const [example, setExample] = useState("");
    const [name, setName] = useState("");

    const [engError, setEngError] = useState(false);
    const [chsError, setChsError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    if (!open) return null;

    function submitContactInfo(e) {
        e.preventDefault()
        // Only reset validation errors for fields that have values
        if (eng) {
            setEngError(false);
        }
        if (chs) {
            setChsError(false);
        }

        // Check if firstName and email are empty
        if (!eng) {
            setEngError(true);
        }
        if (!chs) {
            setChsError(true);
        }

        // If either firstName or email is empty, return early without making the API call
        if (!eng || !chs) {
            return;
        }

        setIsLoading(true);
        turnOffNoResultText();

        const formData = new FormData();
        formData.append('eng', eng);
        formData.append('chs', chs);
        formData.append('example', example);
        formData.append('name', name);

        fetch('/urbanx/word/addWordToSuggestion', {
            method: 'POST',
            body: formData,
        })
            .then((data) => {
                if (data.ok) {
                    setIsLoading(false);
                    onClose();
                } else {
                    setIsLoading(false);
                    turnOnNoResultText();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                turnOnNoResultText();
            });
    }

    const turnOnNoResultText = () => {
        document.getElementById("errorText").style.display = "block";
    }

    const turnOffNoResultText = () => {
        document.getElementById("errorText").style.display = "none";
    }

    return (
        <div onClick={onClose} className='overlay'>
            {isLoading ? <LoadingSpinner /> : ""}
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className='modalContainer'
            >
                <button className='closeButton' onClick={onClose}>X</button>
                <div className='modalRight'>
                    <div className='inputDiv'>
                        <h3>请输入想要加入的短语</h3>
                        <div>
                            <form onSubmit={submitContactInfo}>
                                <label htmlFor="eng">短语</label>
                                <input className={"modalInput"} type="text" id="eng" name="eng" onChange={(e) => setEng(e.target.value)} />
                                {engError && <p style={{ color: 'red' }}>请填写英语短语。</p>}
                                <label htmlFor="chs">解释</label>
                                <input className={"modalInput"} type="text" id="chs" name="chs" onChange={(e) => setChs(e.target.value)} />
                                {chsError && <p style={{ color: 'red' }}>请填写中文解释。</p>}
                                <label htmlFor="example">例句（可选）</label>
                                <input className={"modalInput"} type="text" id="example" name="example" onChange={(e) => setExample(e.target.value)} />
                                <label htmlFor="name">你的名字（可选）</label>
                                <input className={"modalInput"} type="text" id="name" name="name" onChange={(e) => setName(e.target.value)} />
                               </form>
                        </div>
                    </div>
                    <div className='btnContainer'>
                        <button className='btnPrimary' onClick={submitContactInfo}>
                            <span className='bold'>OK</span>
                        </button>
                        <h3 id="errorText">哎呀，失败了，请重试。。。</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalWithInput;
