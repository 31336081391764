import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./Home";
import Title from './Title';
import ManagePage from "./ManagePage";
import Footer from "./Footer";
import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import MyProfile from "./MyProfile";
import {AuthProvider} from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import React from "react";

function App() {
    return (
        <AuthProvider>
            <Router>
                {/*<Title/>*/}
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/guanlizhan" element={<ManagePage/>}/>
                    <Route exact path="/login" element={<LoginPage/>}/>
                    <Route exact path="/register" element={<RegisterPage/>}/>
                    <Route path="/myProfile" element={<ProtectedRoute />}>
                        <Route index element={<MyProfile />} />
                    </Route>
                </Routes>
                <Footer/>
            </Router>
        </AuthProvider>

    );
}

export default App;