import React, {useState} from 'react'
import {useTranslation, Trans} from 'react-i18next';
import './Title.css'
import {useNavigate} from "react-router-dom";
import Switch from "./Switch";

function Title() {
    const {i18n} = useTranslation();
    const navigate = useNavigate();
    const {t} = useTranslation();

    function navigateHome() {
        navigate("/")
    }

    function navigateLogin() {
        navigate("/login")
    }

    function navigateRegister() {
        navigate("/register")
    }

    const [value, setValue] = useState(false);

    const changeLanguage = (changedValue) => {
        setValue(!changedValue)
        i18n.changeLanguage(value ? "en" : "cn")
    }

    return (
        <div>
            <div id="header">
                <div className={"leftCorner"}>
                    <img className="logo" src="logo.jpg" onClick={navigateHome}></img>
                </div>
                <div className={"rightCorner"}>
                    <div className="languageSelector">
                        <p>English/中文</p>
                        <Switch
                            isOn={value}
                            onColor="#EF476F"
                            handleToggle={() => changeLanguage(value)}
                        />
                    </div>
                    <div className="buttonContainer">
                        <button className="log" onClick={navigateLogin}>{t('home.login')}</button>
                        <button className="reg" onClick={navigateRegister}>{t('home.signUp')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Title